* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

nav,
ul {
  list-style: none;
}

a {
  text-decoration: none;
}

.main-container {
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
}

.main-container .hero {
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
}
.main-container .hero .background-img {
  width: 100%;
  min-width: 1000px;
  height: 100vh;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.hero-body {
  flex: 1;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  padding: 88px 0 32px;
  align-items: center;
}
.hero-body img {
  width: 23%;
}
.hero-body .hero-body-bottom {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: center;
  align-items: center;
  width: 15vw;
}
.button-wrapper {
  height: 60px;
  width: 100%;
  position: relative;
}
.button-wrapper img {
  position: absolute;
  width: 100%;

  left: 0;
  height: 100%;
}
.button-wrapper button {
  z-index: 1;
  position: relative;
  height: 100%;
  width: 100%;
  cursor: pointer;
  color: #fff;
  border: none;
  outline: none;
  font-weight: bold;
  font-size: 15px;
  padding-top: 2px;
  background-color: transparent;
}
.hero-body .hero-body-bottom .hero-arrow-icon {
  margin-top: 10px;
}
.banner {
  position: absolute;
  width: 100%;
  height: 18vw;
  top: 96%;
}
.banner img {
  height: 100%;
  width: 100%;
  position: absolute;
}
.banner .banner-presentation {
  z-index: 1;
  position: relative;
  width: 80%;
  margin: 0rem auto;
  display: grid;
  height: 100%;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: space-between;
}
.banner .banner-presentation * {
  color: #fff;
}

.banner .banner-presentation h1 {
  font-size: 3.5rem;
  margin-right: 2rem;
}
.banner .banner-presentation p {
  color: #6e3812;
  font-size: 1.1rem;
  line-height: 1.7rem;
}
.information-section {
  background-color: #1f1a23;
  padding-top: 25vw;
  padding-bottom: 5rem;
}
.information-container-wrapper {
  display: grid;
  width: 80%;
  margin: 0px auto;
}
.information-container-wrapper > * + * {
  margin-top: 4rem;
}
.information-container-wrapper > *:nth-child(2) {
  margin-left: auto;
}
.information-container-wrapper > *:nth-child(2) .information-presentation {
  margin-right: 1rem;
}
.information-container {
  display: flex;
  align-items: flex-end;

  width: 60%;
}

.information-container img {
  width: 300px;
}
.information-presentation {
  color: #fff;

  margin-left: 1rem;
}
.information-presentation h1 {
  font-size: 2.5rem;
  margin-bottom: 10px;
}
.information-presentation h4 {
  font-size: 1.7rem;
  font-weight: 400;
  margin-bottom: 10px;
}
.information-presentation p {
  font-size: 15px;
  line-height: 1.5rem;
}
.last-banner-img {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.checkout-banner {
  height: 35vh;
  position: relative;
  background-color: #1f1a23;
}
.checkout-banner-content {
  position: relative;
  z-index: 1;
  padding: 1rem 0rem;
  width: 80%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  margin: auto;
}
.started-betquest,
.checkout-area {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.started-betquest > h1,
.checkout-area > h1 {
  font-size: 1.7rem;
  color: #fff;
}
.register-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  margin-top: 1rem;
  width: 70%;
}
.checkbox-button-wrapper {
  margin-top: 1rem;
  border: 5px solid #8c4917;
  height: 40px;
  border-radius: 999px;
  background-color: #0e51d1;

  width: 35%;
}
.checkbox-button-wrapper > * {
  color: #fff;
  font-weight: bold;
  font-size: 15px;
  display: block;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.register-form .input-wrapper {
  position: relative;

  height: 40px;
}
.register-form .input-wrapper img {
  position: absolute;
  top: 0;
  left: 0;
  width: 200px;
  height: 100%;
}
.pure-input-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.register-form .input-wrapper input {
  position: relative;
  z-index: 1;
  width: 100%;
  outline: none;
  padding: 0rem 1rem;
  border-radius: 999px;
  border: 5px solid #8c4917;
  height: 100%;
}
.register-button-wrapper {
  border: 5px solid #8c4917;
  height: 40px;
  border-radius: 999px;
  background-color: #0e51d1;
}
.register-button-wrapper button {
  width: 100%;
  height: 100%;
  border-radius: 999px;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: transparent;
  color: #fff;
  font-weight: bold;
  font-size: 15px;
}
.footer-area {
  background-image: url(../../assets/preface/background_top.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.back-glass-cover {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(255, 255, 255, 0.03);
  backdrop-filter: blur(16px);
}

.footer-main {
  position: relative;
  padding-bottom: 41px;
}

.footer-main-content {
  position: relative;
  z-index: 1;
}

.footer-main-content {
  width: 80%;
  margin: auto;
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.footer-main-content-presentation {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4rem;
}
.footer-main-content-presentation > img {
  width: 130px;
}
.footer-main-content-presentation .button-wrapper {
  width: 200px;
  margin-top: 1rem;
}
.latest-news {
  width: 85%;
  margin: auto;
}
.latest-news > h1 {
  color: #fff;
  font-size: 2rem;
  margin-bottom: 1.5rem;
}
.latest-news-reel {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 2rem;
  width: 90%;
  margin: auto;
}
.latest-news-reel > * {
  background-color: transparent;
  height: 300px;
}
@media screen and (max-width: 1200px) {
  .information-container {
    width: 100%;
  }
}
@media screen and (max-width: 1000px) {
  .banner {
    display: none;
  }
  .main-container .hero {
    overflow: hidden;
  }
  .main-container .hero .background-img {
    width: 1000px;
  }
  .checkout-banner {
    height: 50vh;
  }
  .checkout-banner-content {
    grid-template-columns: 1fr;
    grid-row-gap: 2rem;
  }
  .latest-news-reel {
    grid-template-columns: 1fr;
    grid-gap: 2rem;
    width: 100%;
  }
}
@media screen and (max-width: 780px) {
  .information-container {
    flex-direction: column;
    align-items: center;
  }
  .information-container .information-presentation {
    margin: 3rem 0rem;
    text-align: center;
  }
  .information-container-wrapper > *:nth-child(2) {
    flex-direction: column-reverse;
  }
  .hero-body {
    justify-content: center;
  }
  .hero-body > img {
    width: 40%;
  }
  .hero-body .hero-body-bottom {
    flex: 0;
    width: 50%;
  }
}
@media screen and (max-width: 600px) {
  .register-form {
    width: 100%;
  }
  .started-betquest > h1,
  .checkout-area > h1 {
    text-align: center;
  }
  .checkbox-button-wrapper {
    width: 80%;
  }

  .footer-main {
    padding-bottom: 391px;
  }
}
@media screen and (max-width: 500px) {
  .started-betquest > h1,
  .checkout-area > h1 {
    font-size: 25px;
  }
  .information-container img {
    width: 100%;
  }
  .hero-body > img {
    width: 60%;
  }
  .hero-body .hero-body-bottom {
    width: 70%;
    margin-top: 1rem;
  }
}
